import React from 'react';
import cx from "classnames";

import cls from './Button.module.scss';

const Button = ({
  background = 'transparent',
  color = 'danger',
  border = 'danger',
  radius = 'md',
  size='md',
  children,
  onClick,
}) => (
  <button
    onClick={() => onClick && onClick()}
    className={cx(
      cls.wrapper,
      cls[`wrapper--background-${background}`],
      cls[`wrapper--color-${color}`],
      cls[`wrapper--border-${border}`],
      cls[`wrapper--size-${size}`],
      cls[`wrapper--radius-${radius}`],
    )}
  >
    {children}
  </button>
);

export default Button;
