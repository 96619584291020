import get from 'lodash/get';

export const Sphere = (item) => ({
  id: get(item, 'id') || '',
  name: get(item, 'name') || '',
  code: get(item, 'code') || '',
});

export const Meta = (item) => ({
  totalPages: get(item, 'total_pages') || 0,
  totalItems: get(item, 'total_count') || 0,
  current: get(item, 'page') ? get(item, 'page') + 1 : 1,
  perPage: get(item, 'size') || 1
});
