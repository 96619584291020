import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Constants from '../constants';
import * as Mappers from '../mappers';

const useAll = () => {
  const initialData = { items: [] };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY, 'all'],
    async () => {
      const { data } = await Api.All();

      const items = (data || []).map(item => Mappers.OrganizationCategory(item));

      return { items };
    },
    { initialData, keepPreviousData: true }
  );

  return { ...data, ...args };
};

export default useAll;
