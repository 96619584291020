const ENV = process.env;

const config = {
  app: {
    env: ENV.REACT_APP_ENV,
    version: ENV.REACT_APP_VERSION,
    technology: ENV.REACT_APP_TECHNOLOGY,
    isDev: ENV.REACT_APP_ENV !== 'production',
    public_url: ENV.PUBLIC_URL,
    partner_url: ENV.REACT_APP_PARTNER_URL,
  },
  language: {
    key: 'language',
    initial: 'uz',
    list: ['uz', 'oz', 'ru']
  },
  api: {
    base_url: ENV.REACT_APP_API_BASE_URL,
    img_url: `${ENV.REACT_APP_API_BASE_URL}/v1/gov-function/reference/download`,
  },
  perPage: 10
};

export default config;
