import get from 'lodash/get';
import moment from "moment";

import {
  getBasisType,
  getFunction,
  getFunctionUser,
  getMultiName, getNameAndLink,
  getOrganization,
  getReestrProject,
  getTask
} from "helpers/mappers";

export const FunctionRegister = (item) => {
  const createdAt = moment(get(item, 'created_at'), 'YYYY-MM-DD HH:mm:SS');
  const updatedAt = moment(get(item, 'updated_at'), 'YYYY-MM-DD HH:mm:SS');

  return {
    id: get(item, 'id') || '',
    organization: getOrganization(get(item, 'organization_response')),
    function: getFunction(get(item, 'function_name_response')),
    basisDate: get(item, 'basis_date') ? moment(get(item, 'basis_date'), 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
    basisType: getBasisType(get(item, 'basis_type_response')),
    basisNumber: get(item, 'basis_number') || '',
    basisName: getMultiName(get(item, 'basis_name')),
    functionDegree: (get(item, 'degrees') || []).map(degree => get(degree, 'function_degree')),
    departmentName: get(item, 'department_name') || '',
    mechanism: getMultiName(get(item, 'mechanism')),
    mechanismBasisDate: get(item, 'mechanism_basis_date')
      ? moment(get(item, 'mechanism_basis_date'), 'YYYY-MM-DD').format('DD.MM.YYYY')
      : '',
    mechanismBasisType: getBasisType(get(item, 'mechanism_basis_type_response')),
    mechanismBasisNumber: get(item, 'mechanism_basis_number') || '',
    mechanismBasisName: getMultiName(get(item, 'mechanism_basis_name')),
    dedicatedEmployer: get(item, 'dedicated_employer') || '',
    source: get(item, 'source') || '',
    fund: get(item, 'fund') || '',
    currency: get(item, 'currency') || '',
    percentage: get(item, 'percentage') || '',
    personal: get(item, 'personal') || false,
    task: getTask(get(item, 'task_response')),
    activityType: get(item, 'activity_type') || '',
    digitizationStatus: get(item, 'digitization_status') || '',
    functionUsers: (get(item, 'function_users') || []).map(item => getFunctionUser(item)),
    reestrProjects: (get(item, 'reestr_projects') || []).map(item => getReestrProject(item)),
    presentationStatus: (get(item, 'presentation_status') || []).map(item => getNameAndLink(item)),
    otherSystem: (get(item, 'other_system') || []).map(item => getNameAndLink(item)),
    createdAt: get(item, 'created_at') && createdAt.isValid() ? createdAt.format('DD.MM.YYYY') : '',
    updatedAt: get(item, 'updated_at') && updatedAt.isValid() ? updatedAt.format('DD.MM.YYYY') : '',
  };
};