import get from 'lodash/get';
import { useQuery } from 'react-query';

import config from 'config';

import * as Api from '../api';
import * as Constants from '../constants';
import * as Mappers from '../mappers';

const useList = ({ params }) => {
  const initialData = { items: [], meta: Mappers.Meta() };

  const paramsWithDefaults = {
    page: params?.page ? params.page - 1 : 0,
    perPage: params?.perPage || config.perPage,
    sort: {
      name: params?.sort?.name || 'createdAt',
      direction: params?.sort?.direction || 'desc'
    },
    filter: (params?.filter || []).filter(i => !!i.value)
  };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY, 'list', paramsWithDefaults],
    async () => {
      const { data } = await Api.List({
        params: paramsWithDefaults
      });

      const items = (get(data, 'data') || []).map(item => Mappers.Sphere(item));

      const meta = Mappers.Meta(data);

      return { items, meta };
    },
    { initialData, keepPreviousData: true }
  );

  return { ...data, ...args };
};

export default useList;
