import React from 'react';
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { useList } from 'modules/organization/hooks';

import * as Grid from "components/Grid";

import Card from "components/Card";
import Title from "components/Title";
import Spacer from "components/Spacer";
import Button from "components/Button";
import Container from "components/Container";

import cls from './Organization.module.scss';

const Organization = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { items } = useList({
    params: {
      perPage: 8
    }
  });

  if (!items.length) {
    return null;
  }

  return (
    <section className={cls.section}>
      <Container>
        <Title text={t('organizations')} />

        <Spacer size={50} />

        <Grid.Row gutter={[24, 24]}>
          {items
            .sort((a, b) => {
              const nameA = a.name[i18n.language].toUpperCase();
              const nameB = b.name[i18n.language].toUpperCase();
              if(nameA > nameB) return 1;
              if(nameA < nameB) return -1;
              return 0;
            })
            .map(item => (
              <Grid.Col span={6} key={item.id}>
                <Card
                  uuid={item.file.uuid}
                  name={item.name[i18n.language]}
                  count={item.count}
                  onClick={() => navigate(`/function-register?organization_id=${item.id}`)}
                />
              </Grid.Col>
          ))}
        </Grid.Row>

        <div className={cls.btn}>
          <Button onClick={() => navigate('/organizations')}>
            {t('action_more')}
          </Button>
        </div>
      </Container>
    </section>
  )
}

export default Organization;