import React from 'react';

const CheckDone = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.00016 17.5L9.3335 19.8334L14.5835 14.5834M9.3335 9.33337V6.06671C9.3335 4.75992 9.3335 4.10652 9.58781 3.6074C9.81152 3.16835 10.1685 2.8114 10.6075 2.58769C11.1066 2.33337 11.76 2.33337 13.0668 2.33337H21.9335C23.2403 2.33337 23.8937 2.33337 24.3928 2.58769C24.8319 2.8114 25.1888 3.16835 25.4125 3.6074C25.6668 4.10652 25.6668 4.75992 25.6668 6.06671V14.9334C25.6668 16.2402 25.6668 16.8936 25.4125 17.3927C25.1888 17.8317 24.8319 18.1887 24.3928 18.4124C23.8937 18.6667 23.2403 18.6667 21.9335 18.6667H18.6668M6.06683 25.6667H14.9335C16.2403 25.6667 16.8937 25.6667 17.3928 25.4124C17.8319 25.1887 18.1888 24.8317 18.4125 24.3927C18.6668 23.8936 18.6668 23.2402 18.6668 21.9334V13.0667C18.6668 11.7599 18.6668 11.1065 18.4125 10.6074C18.1888 10.1684 17.8319 9.8114 17.3928 9.58769C16.8937 9.33337 16.2403 9.33337 14.9335 9.33337H6.06683C4.76004 9.33337 4.10665 9.33337 3.60752 9.58769C3.16847 9.8114 2.81152 10.1684 2.58781 10.6074C2.3335 11.1065 2.3335 11.7599 2.3335 13.0667V21.9334C2.3335 23.2402 2.3335 23.8936 2.58781 24.3927C2.81152 24.8317 3.16847 25.1887 3.60752 25.4124C4.10665 25.6667 4.76004 25.6667 6.06683 25.6667Z"
          stroke="#6E5BFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
  </svg>
);

export default CheckDone;