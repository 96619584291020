import React from 'react';

const Organization = () => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="52" rx="26" fill="#32BC6C" fillOpacity="0.1"/>
      <path d="M16.25 23H35.75L26 17L16.25 23Z" fill="#32BC6C" fillOpacity="0.1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.607 16.3613C25.8481 16.2129 26.1521 16.2129 26.3932 16.3613L36.1432 22.3613C36.365 22.4978 36.5001 22.7396 36.5001 23C36.5001 23.4142 36.1643 23.75 35.7501 23.75H16.2501C15.9897 23.75 15.7479 23.6149 15.6114 23.3931C15.3943 23.0403 15.5043 22.5783 15.857 22.3613L25.607 16.3613ZM18.8999 22.25L26.0001 17.8806L33.1003 22.25H18.8999Z" fill="#32BC6C"/>
      <path d="M18.5 23V30.5C18.5 30.9142 18.8358 31.25 19.25 31.25C19.6642 31.25 20 30.9142 20 30.5V23C20 22.5858 19.6642 22.25 19.25 22.25C18.8358 22.25 18.5 22.5858 18.5 23Z" fill="#32BC6C"/>
      <path d="M23 23V30.5C23 30.9142 23.3358 31.25 23.75 31.25C24.1642 31.25 24.5 30.9142 24.5 30.5V23C24.5 22.5858 24.1642 22.25 23.75 22.25C23.3358 22.25 23 22.5858 23 23Z" fill="#32BC6C"/>
      <path d="M27.5 23V30.5C27.5 30.9142 27.8358 31.25 28.25 31.25C28.6642 31.25 29 30.9142 29 30.5V23C29 22.5858 28.6642 22.25 28.25 22.25C27.8358 22.25 27.5 22.5858 27.5 23Z" fill="#32BC6C"/>
      <path d="M32 23V30.5C32 30.9142 32.3358 31.25 32.75 31.25C33.1642 31.25 33.5 30.9142 33.5 30.5V23C33.5 22.5858 33.1642 22.25 32.75 22.25C32.3358 22.25 32 22.5858 32 23Z" fill="#32BC6C"/>
      <path d="M17 31.25H35C35.4142 31.25 35.75 30.9142 35.75 30.5C35.75 30.0858 35.4142 29.75 35 29.75H17C16.5858 29.75 16.25 30.0858 16.25 30.5C16.25 30.9142 16.5858 31.25 17 31.25Z" fill="#32BC6C"/>
      <path d="M15.5 34.25H36.5C36.9142 34.25 37.25 33.9142 37.25 33.5C37.25 33.0858 36.9142 32.75 36.5 32.75H15.5C15.0858 32.75 14.75 33.0858 14.75 33.5C14.75 33.9142 15.0858 34.25 15.5 34.25Z" fill="#32BC6C"/>
  </svg>

);

export default Organization;