import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Icons from 'assets/icons';

import * as Grid from 'components/Grid';

import Collapse from './components/Collapce';
import NameAndLink from './components/NameAndLink';

import cls from './DigitizationStatus.module.scss';

const DigitizationStatus = ({
  activityType,
  digitizationStatus,
  functionUsers,
  reestrProjects,
  presentationStatus,
  otherSystem
}) => {
  const { t } = useTranslation();
  const [projectId, setProjectId] = useState(null);

  return (
    <div className={cls.wrapper}>
      <div className={cls.head}>
        <div className={cls.headLeft}>
          <Icons.FolderCode />
        </div>

        <div className={cls.headCenter}>
          <div className={cls.headCenterTitle}>{t('digitization_status')}</div>
          <div className={cls.headCenterStatuses}>
            {digitizationStatus && <div className={cls.headCenterStatus}>{t(digitizationStatus)}</div>}
            {activityType && <div className={cls.headCenterStatus}>{t(activityType)}</div>}
          </div>
        </div>

        <div className={cls.headRight}>
          <div className={cls.headRightTitle}>{t('users_of_the_function_result')}</div>
          <div className={cls.headRightList}>
            {functionUsers.map(item => (
              <div className={cls.headRightItem} key={item.id}>{item.name}</div>
            ))}
          </div>
        </div>
      </div>

      {reestrProjects.length || presentationStatus.length || otherSystem.length ? (
        <div className={cls.body}>
          <Grid.Row gutter={[24, 24]}>
            {reestrProjects.length ? (
              <Grid.Col xs={24}>
                <div className={cls.bodyTitle}>{t('information_system_created_within_the_function')}</div>

                <div className={cls.bodyList}>
                  {reestrProjects.map(item => (
                    <Collapse
                      fullName={item.fullName}
                      shortDescription={item.shortDescription}
                      target={item.target}
                      tasks={item.tasks}
                      open={item.id === projectId}
                      onClick={() => {
                        if (!projectId || projectId !== item.id) {
                          setProjectId(item.id);
                        } else {
                          setProjectId(null);
                        }
                      }}
                      key={item.id}
                    />
                  ))}
                </div>
              </Grid.Col>
            ) : null}

            {presentationStatus.length ? (
              <Grid.Col xs={24}>
                <div className={cls.bodyTitle}>{t('state_of_presentation_in_yidxp')}</div>

                <div className={cls.bodyList}>
                  {presentationStatus.map((item, index) => (
                    <NameAndLink
                      index={index + 1}
                      name={item.name}
                      link={item.link}
                      key={index}
                    />
                  ))}
                </div>
              </Grid.Col>
            ) : null}

            {otherSystem.length ? (
              <Grid.Col xs={24}>
                <div className={cls.bodyTitle}>{t('other_information_systems')}</div>

                <div className={cls.bodyList}>
                  {otherSystem.map((item, index) => (
                    <NameAndLink
                      index={index + 1}
                      name={item.name}
                      link={item.link}
                      key={index}
                    />
                  ))}
                </div>
              </Grid.Col>
            ) : null}
          </Grid.Row>
        </div>
      ) : null}
    </div>
  );
};

export default DigitizationStatus;