import React from 'react';
import cx from "classnames";

import SelectBase  from 'antd/lib/select';

import cls from "./Select.module.scss";

const Select = ({
  state,
  message,
  size = 'large',
  onChange,
  ...props
}) => (
  <div
    className={cx(
      cls.wrapper,
      cls[`wrapper--state-${state}`]
    )}
  >
    <SelectBase
      {...props}
      {...{ size }}
      className={cls.select}
      optionFilterProp="children"
      onChange={e => onChange && onChange(e)}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
    />

    {!!message && <div className={cls.message}>{message}</div>}
  </div>
);

export default Select;
