import React from 'react';
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from 'antd';

import { useAll } from 'modules/sphere/hooks';

import * as Grid from "components/Grid";

import Title from "components/Title";
import Spacer from "components/Spacer";
import Container from "components/Container";
import SphereCard from "components/SphereCard";

const Sphere = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { items } = useAll();

  return (
    <Container>
      <Spacer size={35} />

      <Breadcrumb separator=">">
        <Breadcrumb.Item href='/'>{t('home')}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('spheres')}</Breadcrumb.Item>
      </Breadcrumb>

      <Spacer size={32} />

      <Title align='left' text={t('spheres')} />

      <Spacer size={40} />

      {items.length ? (
        <Grid.Row>
          {items.map(item => (
            <Grid.Col span={6} key={item.id}>
              <SphereCard
                name={item.name}
                code={item.code}
                onClick={() => navigate(`/function-register?sphere_id=${item.id}`)}
              />
            </Grid.Col>
          ))}
        </Grid.Row>
      ) : <div style={{ textAlign: 'center', color: 'red' }}>{t('no_data')}</div>}

      <Spacer size={140} />
    </Container>
  )
}

export default Sphere;