import get from 'lodash/get';

export const Application = (item) => ({
  total: get(item, 'total') || 0,
  accept: get(item, 'accept') || 0,
  process: get(item, 'send') || 0,
  reject: get(item, 'reject') || 0,
  editTotal: get(item, 'edit_total') || 0,
  editAccept: get(item, 'edit_accept') || 0,
  editProcess: get(item, 'edit_send') || 0,
  editReject: get(item, 'edit_reject') || 0,
  registrationTotal: get(item, 'registration_total') || 0,
  registrationAccept: get(item, 'registration_accept') || 0,
  registrationProcess: get(item, 'registration_send') || 0,
  registrationReject: get(item, 'registration_reject') || 0
});

export const DedicatedEmployer = (item) => ({
  less10: get(item, 'less10') || 0,
  between10and100: get(item, 'between10and100') || 0,
  greater100: get(item, 'greater100') || 0
});

export const Summa = (item) => ({
  less10: get(item, 'less10') || 0,
  between10and100: get(item, 'between10and100') || 0,
  greater100: get(item, 'greater100') || 0
});

export const Percentage = (item) => ({
  less25: get(item, 'less25') || 0,
  between26and50: get(item, 'between26and50') || 0,
  greater50: get(item, 'greater50') || 0
});

export const FunctionPersonal = (item) => ({
  personal: get(item, 'personal') || 0,
  notPersonal: get(item, 'not_personal') || 0,
});