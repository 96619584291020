import React from 'react';
import { useTranslation } from "react-i18next";

import * as Icons from 'assets/icons';

import * as Grid from 'components/Grid';

import Container from "components/Container";

import cls from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className={cls.footer}>
      <Container>
        <Grid.Row gutter={80}>
          <Grid.Col span={6}>
            <div className={cls.logo}>
              <Icons.Logo /> <br/>

              <span>
                O’zbekiston Respublikasi <br/>
                Adliya vazirligi
              </span>
            </div>
          </Grid.Col>

          <Grid.Col span={6}>
            <strong className={cls.title}>{t('contact_information')}</strong>

            <ul className={cls.list}>
              <li>
                <Icons.Phone />
                <span className={cls.info}>
                  {t('contact_information_number')}
                </span>
              </li>

              <li>
                <Icons.Email />
                <span className={cls.info}>
                  {t('contact_information_email')}
                </span>
              </li>

              <li>
                <Icons.Location />
                <span className={cls.info}>
                  {t('contact_information_location')}
                </span>
              </li>
            </ul>
          </Grid.Col>

          <Grid.Col span={6}>
            <strong className={cls.title}>{t('main_links')}</strong>

            <ul className={cls.list}>
              <li>
                <Icons.ArrowForward />
                <a className={cls.link} href='/'>
                  {t('projects')}
                </a>
              </li>

              <li>
                <Icons.ArrowForward />
                <a className={cls.link} href='https://regulation.gov.uz'>
                  {t('instructions')}
                </a>
              </li>

              <li>
                <Icons.ArrowForward />
                <a className={cls.link} href='https://adliya.uz'>
                  {t('about_the_registry')}
                </a>
              </li>

              <li>
                <Icons.ArrowForward />
                <a className={cls.link} href='https://www.lex.uz'>
                  {t('feedback')}
                </a>
              </li>
            </ul>
          </Grid.Col>

          <Grid.Col span={6}>
            <strong className={cls.title}>{t('links_to_resources')}</strong>

            <ul className={cls.list}>
              <li>
                <Icons.ArrowForward />
                <a className={cls.link} href='https://mitc.uz'>
                  mitc.uz
                </a>
              </li>

              <li>
                <Icons.ArrowForward />
                <a className={cls.link} href='https://e-gov.uz'>
                  e-gov.uz
                </a>
              </li>

              <li>
                <Icons.ArrowForward />
                <a className={cls.link} href='https://lex.uz'>
                  lex.uz
                </a>
              </li>
            </ul>
          </Grid.Col>
        </Grid.Row>

        <div className={cls.flex}>
          <span className={cls.text}>{t('footer_description')}</span>

          <ul className={cls.networks}>
            <li>
              <a className={cls.network} href='https://telegram.me/huquqiyaxborot' target='_blank'>
                <Icons.Telegram /> <span className={cls.networkTitle}>@huquqiyaxborot</span>
              </a>
            </li>

            <li>
              <a className={cls.network} href='https://telegram.me/antikorbot' target='_blank'>
                <Icons.Telegram /> <span className={cls.networkTitle}>@antikorbot</span>
              </a>
            </li>

            <li>
              <a className={cls.network} href='https://www.facebook.com/minjustuz' target='_blank'>
                <Icons.Facebook /> <span className={cls.networkTitle}>fb.com/minjustuz</span>
              </a>
            </li>

            <li>
              <a className={cls.network} href='https://www.facebook.com/groups/588441011280149' target='_blank'>
                <Icons.Facebook /> <span className={cls.networkTitle}>fb.com/groups/adliya</span>
              </a>
            </li>

            <li>
              <a className={cls.network} href='https://www.instagram.com/adliyanews/' target='_blank'>
                <Icons.Instagram /> <span className={cls.networkTitle}>instagram.com/adliyanews</span>
              </a>
            </li>

            <li>
              <a className={cls.network} href='https://www.youtube.com/c/adliya' target='_blank'>
                <Icons.Youtube /> <span className={cls.networkTitle}>youtube.be/adliya</span>
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  )
}

export default Footer;