import React from 'react';
import { useTranslation } from "react-i18next";

import * as Icons from "assets/icons";

import { useAll as useSpheres } from "modules/sphere/hooks";
import { useAll as useOrganizations } from "modules/organization/hooks";

import * as Grid from "components/Grid";

import Input from "components/Input";
import Select from "components/Select";
import Button from "components/Button";

const FilterList = ({ params, setParams, setSearch }) => {
  const { t, i18n } = useTranslation();

  const sphere = useSpheres();
  const organization = useOrganizations();

  return (
    <Grid.Row gutter={20}>
      <Grid.Col xs={5}>
        <Select
          onChange={v => setParams({ organization_id: v })}
          showSearch
          options={[
            { label: t('all_organization'), value: '' },
            ...organization.items.map(item => ({ label: item.name[i18n.language], value: item.id }))
          ]}
          value={Number(params.organization_id) || ''}
          placeholder={t('column_organization')}
          disabled={!organization.isFetched}
        />
      </Grid.Col>

      <Grid.Col xs={3}>
        <Input
          onChange={e => setParams({ function_code: e })}
          placeholder={t('column_function_code')}
          value={params.function_code}
        />
      </Grid.Col>

      <Grid.Col xs={4}>
        <Input
          onChange={e => setParams({ function_name: e })}
          placeholder={t('column_function_name')}
          value={params.function_name}
        />
      </Grid.Col>

      <Grid.Col xs={4}>
        <Select
          onChange={e => setParams({ personal: e })}
          options={[
            {
              label: t('personal_all'),
              value: ''
            },
            {
              label: t('personal_transferred'),
              value: 'transferred'
            },
            {
              label: t('personal_not_transferred'),
              value: 'not_transferred'
            }
          ]}
          placeholder={t('column_function_status')}
          value={params.personal || ''}
        />
      </Grid.Col>

      <Grid.Col xs={4}>
        <Select
          onChange={v => setParams({ sphere_id: v })}
          showSearch
          options={[
            { label: t('all_sphere'), value: '' },
            ...sphere.items.map(item => ({ label: item.name, value: item.id }))
          ]}
          value={Number(params.sphere_id) || ''}
          placeholder={t('column_sphere')}
          disabled={!sphere.isFetched}
        />
      </Grid.Col>

      <Grid.Col xs={2}>
        <Button
          background='blue'
          color='white'
          border='blue'
          radius='sm'
          onClick={() => setSearch(prev => prev + 1)}
        >
          <Icons.Search /> {t('find')}
        </Button>
      </Grid.Col>
    </Grid.Row>
  )
}

export default FilterList;