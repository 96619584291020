import get from 'lodash/get';

import sizeFormat from 'helpers/sizeFormat';

export const Organization = (item) => ({
  id: get(item, 'id') || '',
  name: {
    uz: get(item, 'name.uz') || '',
    oz: get(item, 'name.oz') || '',
    ru: get(item, 'name.ru') || ''
  },
  parentId: get(item, 'parent.id') || '',
  count: get(item, 'count') || 0,
  category: Category(get(item, 'category_response')),
  file: File(get(item, 'file_response'))
});

export const File = (item) => ({
  id: get(item, 'id') || '',
  uuid: get(item, 'uuid') || '',
  name: get(item, 'file_name') || '',
  size: sizeFormat(get(item, 'size') || 0),
  extension: get(item, 'extension') || ''
});

export const Category = (item)=> ({
  id: get(item, 'id') || '',
  name: get(item, 'name') || ''
});


export const Meta = (item) => ({
  totalPages: get(item, 'total_pages') || 0,
  totalItems: get(item, 'total_count') || 0,
  current: get(item, 'page') ? get(item, 'page') + 1 : 1,
  perPage: get(item, 'size') || 1
});
