import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useUrlSearchParams } from "use-url-search-params";
import { Breadcrumb, Table } from 'antd';

import { useList } from 'modules/functionRegister/hooks';

import Pagination from "containers/Pagination";

import Tag from "components/Tag";
import Title from "components/Title";
import Spacer from "components/Spacer";
import Container from "components/Container";

import FilterList from "./components/FilterList";

const RegisterFunction = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [params, setParams] = useUrlSearchParams();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState(0);

  const { items, meta, isFetched } = useList({
    params: {
      search,
      page,
      filter: [
        {
          key: 'created_by_organization.id',
          operation: '=',
          value: params.organization_id,
          type: 'STRING'
        },
        {
          key: 'function_name.sphere.id',
          operation: '=',
          value: params.sphere_id,
          type: 'NUMBER'
        },
        {
          key: 'function_name.code',
          operation: '%_%',
          value: params.function_code,
          type: 'STRING'
        },
        {
          key: `function_name.name.${i18n.language}`,
          operation: '%_%',
          value: params.function_name,
          type: 'JSON'
        },
        {
          key: 'personal',
          operation: '=',
          value: params.personal === 'transferred'
                    ? 'true' : params.personal === 'not_transferred'
                    ? 'false' : '',
          type: 'BOOL'
        }
      ]
    }
  });

  return (
    <Container>
      <Spacer size={35} />

      <Breadcrumb separator=">">
        <Breadcrumb.Item href='/'>{t('home')}</Breadcrumb.Item>
        <Breadcrumb.Item >{t('function_register')}</Breadcrumb.Item>
      </Breadcrumb>

      <Spacer size={32} />

      <Title align='left' text={t('function_register')} />

      <Spacer size={40} />

      <FilterList {...{ params, setParams, setSearch }} />

      <Spacer size={20} />

      <Table
        rowKey="id"
        defaultSort="-createdAt"
        columns={[
          {
            title: '№',
            render: (text, record, index) => (meta.current - 1) * meta.perPage + index + 1
          },
          {
            key: 'code',
            title: t('column_function_code'),
            dataIndex: ['function', 'code']
          },
          {
            key: 'function_name',
            title: t('column_function_name'),
            dataIndex: ['function', 'name', i18n.language]
          },
          {
            key: 'function_name',
            title: t('column_basis_name'),
            dataIndex: ['basisName', i18n.language]
          },
          {
            key: 'function_name',
            title: t('column_basis_date'),
            dataIndex: 'basisDate'
          },
          {
            key: 'function_name',
            title: t('column_basis_number'),
            dataIndex: 'basisNumber'
          },
          {
            key: 'organization',
            title: t('column_organization'),
            dataIndex: ['organization', 'name', i18n.language]
          },
          {
            key: 'personal',
            title: t('column_function_status'),
            dataIndex: 'personal',
            render: value => (
              <Tag variant={value ? 'green' : 'orange'}>
                {value ? t('personal_transferred') : t('personal_not_transferred')}
              </Tag>
            )
          },
        ]}
        dataSource={items}
        pagination={false}
        loading={!isFetched}
        onRow={item => ({
          onClick: () => navigate(`/function-register/view/${item.id}`)
        })}
        scroll={{ x: 1400 }}
      />

      <Spacer size={32} />

      <Pagination
        current={meta.current}
        total={meta.totalPages}
        currentItems={items.length}
        totalItems={meta.totalItems}
        onChange={page => setPage(page)}
      />

      <Spacer size={140} />
    </Container>
  )
}

export default RegisterFunction;