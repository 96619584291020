import React from 'react';
import cx from 'classnames';

import cls from './Tag.module.scss';

const Tag = ({ variant = 'blue', children }) => (
  <div className={cx(cls.wrapper, cls[`wrapper--variant-${variant}`])}>{children}</div>
);

export default Tag;
