import React from 'react';

const Download = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_361_14123" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_361_14123)">
      <path d="M6 19.9991C5.45 19.9991 4.97933 19.8035 4.588 19.4121C4.196 19.0201 4 18.5491 4 17.9991V14.9991H6V17.9991H18V14.9991H20V17.9991C20 18.5491 19.8043 19.0201 19.413 19.4121C19.021 19.8035 18.55 19.9991 18 19.9991H6ZM12 15.9991L7 10.9991L8.4 9.54915L11 12.1491V3.99915H13V12.1491L15.6 9.54915L17 10.9991L12 15.9991Z" fill="white"/>
    </g>
  </svg>
);

export default Download;