import React from 'react';

const Dollar = () => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="52" rx="26" fill="#32BC6C" fillOpacity="0.1"/>
      <path d="M35 26C35 30.9706 30.9706 35 26 35C21.0294 35 17 30.9706 17 26C17 21.0294 21.0294 17 26 17C30.9706 17 35 21.0294 35 26Z" fill="#32BC6C" fillOpacity="0.1"/>
      <path d="M26 20C25.5858 20 25.25 20.3358 25.25 20.75V22.25C25.25 22.6642 25.5858 23 26 23C26.4142 23 26.75 22.6642 26.75 22.25V20.75C26.75 20.3358 26.4142 20 26 20Z" fill="#32BC6C"/>
      <path d="M26 29C25.5858 29 25.25 29.3358 25.25 29.75V31.25C25.25 31.6642 25.5858 32 26 32C26.4142 32 26.75 31.6642 26.75 31.25V29.75C26.75 29.3358 26.4142 29 26 29Z" fill="#32BC6C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26 16.25C26 16.25 27.9831 16.25 29.7954 17.0165C29.7954 17.0165 31.5452 17.7566 32.8943 19.1057C32.8943 19.1057 34.2434 20.4548 34.9835 22.2046C34.9835 22.2046 35.75 24.0169 35.75 26C35.75 26 35.75 27.9831 34.9835 29.7954C34.9835 29.7954 34.2434 31.5452 32.8943 32.8943C32.8943 32.8943 31.5452 34.2434 29.7954 34.9835C29.7954 34.9835 27.9831 35.75 26 35.75C26 35.75 24.0169 35.75 22.2046 34.9835C22.2046 34.9835 20.4548 34.2434 19.1057 32.8943C19.1057 32.8943 17.7566 31.5452 17.0165 29.7954C17.0165 29.7954 16.25 27.9831 16.25 26C16.25 26 16.25 24.0169 17.0165 22.2046C17.0165 22.2046 17.7566 20.4548 19.1057 19.1057C19.1057 19.1057 20.4548 17.7566 22.2046 17.0165C22.2046 17.0165 24.0169 16.25 26 16.25ZM26 17.75C26 17.75 24.321 17.75 22.789 18.398C22.789 18.398 21.3086 19.0242 20.1664 20.1664C20.1664 20.1664 19.0242 21.3086 18.398 22.789C18.398 22.789 17.75 24.321 17.75 26C17.75 26 17.75 27.679 18.398 29.211C18.398 29.211 19.0242 30.6914 20.1664 31.8336C20.1664 31.8336 21.3086 32.9758 22.789 33.602C22.789 33.602 24.321 34.25 26 34.25C26 34.25 27.679 34.25 29.211 33.602C29.211 33.602 30.6914 32.9758 31.8336 31.8336C31.8336 31.8336 32.9758 30.6914 33.602 29.211C33.602 29.211 34.25 27.6789 34.25 26C34.25 26 34.25 24.3211 33.602 22.789C33.602 22.789 32.9758 21.3086 31.8336 20.1664C31.8336 20.1664 30.6914 19.0242 29.211 18.398C29.211 18.398 27.679 17.75 26 17.75Z" fill="#32BC6C"/>
      <path d="M24.875 23H28.25C28.6642 23 29 22.6642 29 22.25C29 21.8358 28.6642 21.5 28.25 21.5H24.875C23.7877 21.5 23.0188 22.2688 23.0188 22.2688C22.25 23.0377 22.25 24.125 22.25 24.125C22.25 25.2123 23.0188 25.9812 23.0188 25.9812C23.7877 26.75 24.875 26.75 24.875 26.75H27.125C27.591 26.75 27.9205 27.0795 27.9205 27.0795C28.25 27.409 28.25 27.875 28.25 27.875C28.25 28.341 27.9205 28.6705 27.9205 28.6705C27.591 29 27.125 29 27.125 29H23.75C23.3358 29 23 29.3358 23 29.75C23 30.1642 23.3358 30.5 23.75 30.5H27.125C28.2123 30.5 28.9812 29.7312 28.9812 29.7312C29.75 28.9623 29.75 27.875 29.75 27.875C29.75 26.7877 28.9812 26.0188 28.9812 26.0188C28.2123 25.25 27.125 25.25 27.125 25.25H24.875C24.409 25.25 24.0795 24.9205 24.0795 24.9205C23.75 24.591 23.75 24.125 23.75 24.125C23.75 23.659 24.0795 23.3295 24.0795 23.3295C24.409 23 24.875 23 24.875 23Z" fill="#32BC6C"/>
  </svg>
);

export default Dollar;