import React from 'react';

const Persons = () => (
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="52" height="52" rx="26" fill="#32BC6C" fillOpacity="0.1"/>
        <path d="M29.75 27.125C29.75 29.1961 28.0711 30.875 26 30.875C23.9289 30.875 22.25 29.1961 22.25 27.125C22.25 25.0539 23.9289 23.375 26 23.375C28.0711 23.375 29.75 25.0539 29.75 27.125Z" fill="#32BC6C" fillOpacity="0.1"/>
        <path d="M22.625 21.875C22.625 23.5319 21.2819 24.875 19.625 24.875C17.9681 24.875 16.625 23.5319 16.625 21.875C16.625 20.2181 17.9681 18.875 19.625 18.875C21.2819 18.875 22.625 20.2181 22.625 21.875Z" fill="#32BC6C" fillOpacity="0.1"/>
        <path d="M35.375 21.875C35.375 23.5319 34.0319 24.875 32.375 24.875C30.7181 24.875 29.375 23.5319 29.375 21.875C29.375 20.2181 30.7181 18.875 32.375 18.875C34.0319 18.875 35.375 20.2181 35.375 21.875Z" fill="#32BC6C" fillOpacity="0.1"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M26 22.625C26 22.625 27.864 22.625 29.182 23.943C29.182 23.943 30.5 25.261 30.5 27.125C30.5 27.125 30.5 28.989 29.182 30.307C29.182 30.307 27.864 31.625 26 31.625C26 31.625 24.136 31.625 22.818 30.307C22.818 30.307 21.5 28.989 21.5 27.125C21.5 27.125 21.5 25.261 22.818 23.943C22.818 23.943 24.136 22.625 26 22.625ZM26 24.125C26 24.125 24.7574 24.125 23.8787 25.0037C23.8787 25.0037 23 25.8824 23 27.125C23 27.125 23 28.3676 23.8787 29.2463C23.8787 29.2463 24.7574 30.125 26 30.125C26 30.125 27.2426 30.125 28.1213 29.2463C28.1213 29.2463 29 28.3676 29 27.125C29 27.125 29 25.8824 28.1213 25.0037C28.1213 25.0037 27.2426 24.125 26 24.125Z" fill="#32BC6C"/>
        <path d="M36.274 27.5737L36.2743 27.574C36.3934 27.7333 36.5709 27.8388 36.7677 27.8673C36.8033 27.8724 36.8391 27.875 36.875 27.875L36.8764 27.875C37.0377 27.8747 37.1947 27.8224 37.324 27.7258C37.5135 27.5842 37.625 27.3615 37.625 27.125L37.625 27.1164C37.6232 26.9575 37.5709 26.8033 37.4758 26.676L37.4751 26.6752C36.5737 25.4694 35.227 24.796 35.227 24.796C33.8799 24.1225 32.3738 24.125 32.3738 24.125L32.375 24.125C32.7892 24.125 33.125 24.4608 33.125 24.875C33.125 25.2886 32.7903 25.6241 32.3767 25.625L36.274 27.5737Z" fill="#32BC6C"/>
        <path d="M17.4438 26.1377C18.4731 25.6231 19.6237 25.625 19.6237 25.625L19.6248 25.625H19.625C19.6366 25.625 19.6482 25.6247 19.6598 25.6242C19.846 25.6156 20.0224 25.5378 20.1544 25.4062C20.2953 25.2658 20.3747 25.0752 20.375 24.8763L20.375 24.8752V24.875C20.375 24.8296 20.3709 24.7844 20.3627 24.7398C20.2975 24.3841 19.9878 24.1256 19.6263 24.125L19.625 24.125C18.1196 24.1228 16.773 24.796 16.773 24.796C15.4264 25.4694 14.5249 26.6752 14.5249 26.6752L14.5243 26.6759C14.4274 26.8057 14.375 26.9632 14.375 27.125L14.375 27.1329C14.3754 27.1661 14.378 27.1994 14.3827 27.2323C14.4112 27.4292 14.5167 27.6067 14.676 27.7258C14.8056 27.8227 14.9632 27.875 15.125 27.875C15.135 27.875 15.1451 27.8748 15.1551 27.8744C15.3808 27.8653 15.5905 27.755 15.7258 27.574C16.4146 26.6523 17.4438 26.1377 17.4438 26.1377Z" fill="#32BC6C"/>
        <path d="M28.7861 32.4259C30.0641 33.2241 30.7255 34.5777 30.7255 34.5777L30.7259 34.5786L30.7262 34.5791C30.8519 34.8366 31.1135 35 31.4001 35L31.4206 34.9997C31.5277 34.9968 31.6329 34.9709 31.7292 34.9239C31.9867 34.7982 32.1501 34.5366 32.1501 34.25L32.1498 34.2294C32.1469 34.1224 32.121 34.0172 32.074 33.9209L32.0738 33.9203C31.2237 32.1798 29.5807 31.1537 29.5807 31.1537C27.9375 30.1274 26.0001 30.1274 26.0001 30.1274C24.0627 30.1274 22.4195 31.1537 22.4195 31.1537C20.7765 32.1798 19.9264 33.9203 19.9264 33.9203L19.9262 33.9208C19.8761 34.0234 19.8501 34.1359 19.8501 34.25L19.8502 34.2597C19.8512 34.3394 19.8649 34.4185 19.8908 34.4938C19.9555 34.6819 20.0923 34.8366 20.271 34.9239C20.3735 34.974 20.486 35 20.6001 35L20.6173 34.9998C20.8977 34.9933 21.151 34.831 21.274 34.5791C21.9355 33.2245 23.2141 32.4259 23.2141 32.4259C24.4926 31.6274 26.0001 31.6274 26.0001 31.6274C27.5076 31.6274 28.7861 32.4259 28.7861 32.4259Z" fill="#32BC6C"/>
        <path d="M21.2619 20.3344C21.7096 20.8109 21.8322 21.4531 21.8322 21.4531C21.8695 21.6485 21.9829 21.8211 22.1474 21.9329C22.2717 22.0173 22.4186 22.0625 22.5689 22.0625L22.5737 22.0625C22.6193 22.0622 22.6647 22.0578 22.7095 22.0492C23.0631 21.9817 23.3189 21.6725 23.3189 21.3125L23.3189 21.3083C23.3186 21.2625 23.3142 21.2169 23.3056 21.1719C23.1013 20.1015 22.3551 19.3073 22.3551 19.3073C21.609 18.5131 20.5534 18.2425 20.5534 18.2425C19.4979 17.9719 18.4616 18.3091 18.4616 18.3091C17.4254 18.6463 16.7313 19.4863 16.7313 19.4863C16.0372 20.3263 15.9013 21.4075 15.9013 21.4075C15.7655 22.4887 16.2302 23.4744 16.2302 23.4744C16.695 24.46 17.6156 25.0431 17.6156 25.0431C18.5359 25.6259 19.6251 25.625 19.6251 25.625L19.6259 25.625C19.8248 25.6248 20.0155 25.5456 20.156 25.4048C20.2963 25.2642 20.3751 25.0737 20.3751 24.875L20.3751 24.8743C20.3747 24.4603 20.039 24.125 19.6251 24.125L19.6244 24.125C18.9705 24.1257 18.4182 23.7758 18.4182 23.7758C17.8658 23.426 17.587 22.8346 17.587 22.8346C17.3081 22.2433 17.3896 21.5945 17.3896 21.5945C17.4711 20.9458 17.8876 20.4418 17.8876 20.4418C18.3041 19.9378 18.9258 19.7355 18.9258 19.7355C19.5475 19.5331 20.1809 19.6955 20.1809 19.6955C20.8142 19.8579 21.2619 20.3344 21.2619 20.3344Z" fill="#32BC6C"/>
        <path d="M33.5818 23.7758C33.0295 24.1257 32.3757 24.125 32.3757 24.125L32.3749 24.125C32.3631 24.125 32.3514 24.1253 32.3396 24.1258L31.6257 24.9103C31.6345 25.0965 31.7123 25.2728 31.844 25.4048C31.9845 25.5456 32.1752 25.6248 32.3741 25.625L32.3749 25.625C33.4642 25.6259 34.3844 25.0431 34.3844 25.0431C35.305 24.46 35.7698 23.4744 35.7698 23.4744C36.2345 22.4887 36.0987 21.4075 36.0987 21.4075C35.9629 20.3263 35.2687 19.4863 35.2687 19.4863C34.5746 18.6463 33.5384 18.3091 33.5384 18.3091C32.5022 17.9719 31.4466 18.2425 31.4466 18.2425C30.391 18.5131 29.6449 19.3073 29.6449 19.3073C28.8988 20.1015 28.6945 21.1719 28.6945 21.1719C28.6856 21.2182 28.6812 21.2653 28.6812 21.3125C28.6812 21.3351 28.6822 21.3576 28.6842 21.38C28.7143 21.7134 28.9617 21.9864 29.2905 22.0492C29.3369 22.0581 29.384 22.0625 29.4312 22.0625C29.4537 22.0625 29.4762 22.0615 29.4987 22.0595C29.832 22.0293 30.1051 21.7819 30.1679 21.4531C30.2904 20.8109 30.7381 20.3344 30.7381 20.3344C31.1858 19.8579 31.8191 19.6955 31.8191 19.6955C32.4525 19.5331 33.0742 19.7355 33.0742 19.7355C33.6959 19.9378 34.1124 20.4418 34.1124 20.4418C34.5289 20.9458 34.6104 21.5945 34.6104 21.5945C34.6919 22.2433 34.413 22.8346 34.413 22.8346C34.1342 23.426 33.5818 23.7758 33.5818 23.7758Z" fill="#32BC6C"/>
  </svg>
);

export default Persons;