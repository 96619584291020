import React from 'react';

const Instagram = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5487 6.99268H9.45558C8.00499 6.99268 6.82104 8.17134 6.82104 9.62734V14.7207C6.82104 16.1713 7.99965 17.3553 9.45558 17.3553H14.5487C15.9993 17.3553 17.1832 16.1767 17.1832 14.7207V9.62734C17.1779 8.17134 15.9993 6.99268 14.5487 6.99268ZM11.9995 15.5793C10.1222 15.5793 8.59162 14.0487 8.59162 12.1713C8.59162 10.294 10.1222 8.76334 11.9995 8.76334C13.8767 8.76334 15.4073 10.294 15.4073 12.1713C15.4073 14.0487 13.8767 15.5793 11.9995 15.5793ZM15.5779 9.14201C15.258 9.14201 15.002 8.88601 15.002 8.56601C15.002 8.24601 15.258 7.99001 15.5779 7.99001C15.8979 7.99001 16.1539 8.24601 16.1539 8.56601C16.1539 8.88601 15.8979 9.14201 15.5779 9.14201Z" fill="white"/>
    <path d="M14.0712 12.1966C14.085 11.0538 13.1697 10.1162 12.0269 10.1025C10.884 10.0888 9.94651 11.0041 9.93279 12.147C9.91908 13.2898 10.8344 14.2274 11.9772 14.2411C13.12 14.2549 14.0575 13.3395 14.0712 12.1966Z" fill="white"/>
    <path d="M11.9998 0.171387C5.37042 0.171387 -0.000244141 5.54205 -0.000244141 12.1714C-0.000244141 18.8007 5.37042 24.1714 11.9998 24.1714C18.6291 24.1714 23.9998 18.8007 23.9998 12.1714C23.9998 5.54205 18.6291 0.171387 11.9998 0.171387ZM18.6664 14.7207C18.6664 16.9927 16.8211 18.8381 14.5491 18.8381H9.45576C7.18376 18.8381 5.33842 16.9927 5.33842 14.7207V9.62739C5.33842 7.35539 7.18376 5.51005 9.45576 5.51005H14.5491C16.8211 5.51005 18.6664 7.35539 18.6664 9.62739V14.7207Z" fill="white"/>
  </svg>
);

export default Instagram;