import React from 'react';
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";

import { useApplication } from "modules/statistic/hooks";

import Spacer from "components/Spacer";
import Container from "components/Container";

import Chart from "./sections/Chart";
import DoneWork from "./sections/DoneWork";

const Statistic = () => {
  const { t } = useTranslation();

  const { item } = useApplication();

  return (
    <section>
      <Container>
        <Spacer size={35} />

        <Breadcrumb separator=">">
          <Breadcrumb.Item href='/'>{t('home')}</Breadcrumb.Item>
          <Breadcrumb.Item >{t('statistics')}</Breadcrumb.Item>
        </Breadcrumb>

        <Spacer size={32} />

        <DoneWork
          title={t('application_registration_statistics')}
          statistic={{
            total: item.registrationTotal,
            accept: item.registrationAccept,
            process: item.registrationProcess,
            reject: item.registrationReject
          }}
        />

        <Spacer size={100} />

        <DoneWork
          title={t('application_edit_statistics')}
          statistic={{
            total: item.editTotal,
            accept: item.editAccept,
            process: item.editProcess,
            reject: item.editReject
          }}
        />

        <Spacer size={100} />

        <Chart />

        <Spacer size={200} />
      </Container>
    </section>
  );
}

export default Statistic;