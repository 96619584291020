import React from 'react';

const Copy = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_437_7629)">
      <path
        d="M7.875 1.5021C7.36871 1.50896 7.06477 1.53827 6.81901 1.66349C6.53677 1.8073 6.3073 2.03677 6.16349 2.31901C6.03827 2.56477 6.00896 2.86871 6.0021 3.375M14.625 1.5021C15.1313 1.50896 15.4352 1.53827 15.681 1.66349C15.9632 1.8073 16.1927 2.03677 16.3365 2.31901C16.4617 2.56477 16.491 2.86871 16.4979 3.37499M16.4979 10.125C16.491 10.6313 16.4617 10.9352 16.3365 11.181C16.1927 11.4632 15.9632 11.6927 15.681 11.8365C15.4352 11.9617 15.1313 11.991 14.625 11.9979M16.5 5.99999V7.49999M10.5 1.5H12M3.9 16.5H9.6C10.4401 16.5 10.8601 16.5 11.181 16.3365C11.4632 16.1927 11.6927 15.9632 11.8365 15.681C12 15.3601 12 14.9401 12 14.1V8.4C12 7.55992 12 7.13988 11.8365 6.81901C11.6927 6.53677 11.4632 6.3073 11.181 6.16349C10.8601 6 10.4401 6 9.6 6H3.9C3.05992 6 2.63988 6 2.31901 6.16349C2.03677 6.3073 1.8073 6.53677 1.66349 6.81901C1.5 7.13988 1.5 7.55992 1.5 8.4V14.1C1.5 14.9401 1.5 15.3601 1.66349 15.681C1.8073 15.9632 2.03677 16.1927 2.31901 16.3365C2.63988 16.5 3.05992 16.5 3.9 16.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_437_7629">
        <rect width="18" height="18" fill="white"/>
      </clipPath>
    </defs>
  </svg>

);

export default Copy;