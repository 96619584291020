import React from 'react';
import ReactPaginate from "react-paginate";

import * as Icons from 'assets/icons';

import cls from './Pagination.module.scss';

const Pagination = ({ total, current, pageRangeDisplayed = 5, onChange }) => (
   <ReactPaginate
      {...{ pageRangeDisplayed }}
      breakLabel="..."
      pageCount={total}
      forcePage={current - 1}
      onPageChange={({ selected }) => onChange(selected + 1)}
      nextLabel={<Icons.Right />}
      previousLabel={<Icons.Left />}
      containerClassName={cls.container}
      activeClassName={cls.active}
      pageClassName={cls.page}
      nextClassName={cls.btn}
      previousClassName={cls.btn}
   />
);

export default Pagination;