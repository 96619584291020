import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from "classnames";

import * as Icons from "assets/icons";

import cls from './Collapce.module.scss';

const Collapse = ({ fullName, shortDescription, target, tasks, open, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(cls.wrapper, open && cls['wrapper--open'])}>
      <div className={cls.head} onClick={onClick && onClick}>
        <div>
          <Icons.CheckDone />
        </div>

        <div className={cls.headName}>{fullName}</div>

        <div className={cls.headIcon}>
          {open ? <Icons.Minus /> : <Icons.Plus />}
        </div>
      </div>

      {open && (
        <div className={cls.body}>
          <div className={cls.item}>
            <div className={cls.itemKey}>{t('collapse_short_description')}</div>
            <div className={cls.itemValue}>{shortDescription}</div>
          </div>

          <div className={cls.item}>
            <div className={cls.itemKey}>{t('collapse_target')}</div>
            <div className={cls.itemValue}>{target}</div>
          </div>

          <div className={cls.item}>
            <div className={cls.itemKey}>{t('collapse_tasks')}</div>
            <div className={cls.itemValue}>{tasks}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Collapse;