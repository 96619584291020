import { http } from 'services';

export const Application = () => http.request.get('/v1/gov-function/open-statistic/application');

export const Summa = () => http.request.get('/v1/gov-function/open-statistic/summa');

export const Percentage = () => http.request.get('/v1/gov-function/open-statistic/percentage');

export const FunctionalPersonal = () => http.request.get('/v1/gov-function/open-statistic/function-personal');

export const DedicatedEmployer = () => http.request.get('/v1/gov-function/open-statistic/dedicated-employer');