import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import * as Icons from 'assets/icons';

import config from 'config';

import Input from "components/Input";
import Container from "components/Container";

import cls from './Hero.module.scss';

const Hero = () => {
  const { t }  = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState();

  return (
    <section className={cls.section}>
      <Container>
        <div className={cls.content}>
          <h1 className={cls.title}>{t('open_register_of_functions_title')}</h1>

          <p className={cls.description}>{t('open_register_of_functions_description')}</p>

          <a className={cls.enter} href={config.app.partner_url} target='_blank'>
            {t('action_enter')}
          </a>

          <div className={cls.search}>
            <Input
              onChange={e => setSearch(e)}
              onKeyPress={e => {
                if(e.key === 'Enter'){
                  navigate(`/function-register?function_name=${search}`);
                }
              }}
              placeholder={t('search_by_function_name')}
              value={search}
            />
            <div
              className={cls.icon}
              onClick={() => navigate(`/function-register?function_name=${search}`)}
            >
              <Icons.Search />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Hero;