import React from 'react';
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { Card } from "antd";

import * as Hooks from 'modules/statistic/hooks';

import * as Grid from "components/Grid";

const style = {
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
  color: '#000C74'
}

const Chart = () => {
  const { t } = useTranslation();

  const { item: summa } = Hooks.useSumma();
  const { item: percentage } = Hooks.usePercentage();
  const { item: functionPersonal } = Hooks.useFunctionPersonal();
  const { item: dedicatedEmployer } = Hooks.useDedicatedEmployer();

  const state1 = {
    series: [
      dedicatedEmployer.greater100,
      dedicatedEmployer.between10and100,
      dedicatedEmployer.less10
    ],
    options: {
      chart: {
        type: 'pie',
        toolbar: {
          show: true
        }
      },
      legend: {
        offsetY: 20,
        offsetX: -20,
        width: 250,
      },
      title: {
        text: t('fund_percentage_statistics'),
        style,
      },
      labels: [
        t('greater_100') + ': ' + dedicatedEmployer.greater100,
        t('between_10_and_100') + ': ' + dedicatedEmployer.between10and100,
        t('less_10') + ': ' + dedicatedEmployer.less10
      ],
      colors: ['#32BC6C', '#FF7A00', '#ff007a']
    }
  };

  const state2 = {
    series: [
      summa.greater100,
      summa.between10and100,
      summa.less10
    ],
    options: {
      chart: {
        type: 'pie',
        toolbar: {
          show: true
        }
      },
      legend: {
        offsetY: 20,
        offsetX: -20,
        width: 250,
      },
      title: {
        text: t('amount_of_money_statistics'),
        style,
      },
      labels: [
        t('greater_100mln') + ': ' + summa.greater100,
        t('between_10mln_and_100mln') + ': ' + summa.between10and100,
        t('less_10mln') + ': ' + summa.less10
      ],
      colors: ['#32BC6C', '#FF7A00', '#ff007a']
    }
  };

  const state3 = {
    series: [percentage.greater50, percentage.between26and50, percentage.less25],
    options: {
      chart: {
        type: 'pie',
        toolbar: {
          show: true
        }
      },
      legend: {
        offsetY: 20,
        offsetX: -20,
        width: 250
      },
      title: {
        text: t('percentage_statistics'),
        style
      },
      labels: [
        `${t('greater_50')}: ${percentage.greater50}`,
        `${t('between_26_and_50')}: ${percentage.between26and50}`,
        `${t('less_25')}: ${percentage.less25}`
      ],
      colors: ['#32BC6C', '#FF7A00', '#ff007a']
    }
  };

  const state4 = {
    series: [
      functionPersonal.personal,
      functionPersonal.notPersonal
    ],
    options: {
      chart: {
        type: 'pie',
        toolbar: {
          show: true
        }
      },
      legend: {
        offsetY: 20,
        offsetX: -20,
        width: 250,
      },
      title: {
        text: t('personal_statistics'),
        style,
      },
      labels: [
        t('personal') + ': ' + functionPersonal.personal,
        t('not_personal') + ': ' + functionPersonal.notPersonal
      ],
      colors: ['#32BC6C', '#ff007a']
    }
  };

  return (
    <Grid.Row gutter={[24, 24]}>
      <Grid.Col xs={12}>
        <Card>
          <ReactApexChart
            options={state1.options}
            series={state1.series}
            type="pie"
            height={300}
          />
        </Card>
      </Grid.Col>

      <Grid.Col xs={12}>
        <Card>
          <ReactApexChart
            options={state2.options}
            series={state2.series}
            type="pie"
            height={300}
          />
        </Card>
      </Grid.Col>

      <Grid.Col xs={12}>
        <Card>
          <ReactApexChart
            options={state3.options}
            series={state3.series}
            type="pie"
            height={300}
          />
        </Card>
      </Grid.Col>

      <Grid.Col xs={12}>
        <Card>
          <ReactApexChart
            options={state4.options}
            series={state4.series}
            type="pie"
            height={300}
          />
        </Card>
      </Grid.Col>
    </Grid.Row>
  )
}

export default Chart;