import React from 'react';
import { Collapse, theme } from "antd";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import * as Icons from 'assets/icons';

import { useList } from "modules/faq/hooks";

import Title from "components/Title";
import Spacer from "components/Spacer";
import Container from "components/Container";

import cls from './Faq.module.scss';

const { Panel } = Collapse;

const Faq = () => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const { items } = useList({
    params: {
      perPage: 6
    }
  });

  if (!items.length) {
    return null;
  }

  return (
    <section className={cls.section}>
      <Container>
        <div className={cls.content}>
          <Title variant='white' text={t('faq_title')} />

          <Spacer size={32} />

          <p className={cls.description}>{t('faq_description')}</p>

          <Collapse
            bordered={false}
            accordion
            style={{ backgroundColor: 'transparent' }}
            expandIcon={({ isActive }) => (
              <div className={cx(cls.chevron, isActive && cls[`chevron-active`])}>
                <Icons.ChevronDown />
              </div>
            )}
          >
            {items.map(item => (
              <Panel header={item.title} key="1" style={{
                marginBottom: 24,
                background: '#fff',
                borderRadius: token.borderRadiusLG,
                border: 'none',
              }}>
                <p>{item.description}</p>
              </Panel>
            ))}
          </Collapse>
        </div>
      </Container>
    </section>
  )
}

export default Faq;