import React from 'react';

const Mechanism = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="10" fill="#6E5BFF" fillOpacity="0.1"/>
    <path d="M40.7333 41.6666C41.0599 41.6666 41.2233 41.6666 41.3481 41.6031C41.4578 41.5471 41.5471 41.4579 41.603 41.3481C41.6666 41.2234 41.6666 41.06 41.6666 40.7333V28.6C41.6666 28.2733 41.6666 28.1099 41.603 27.9852C41.5471 27.8754 41.4578 27.7862 41.3481 27.7302C41.2233 27.6666 41.0599 27.6666 40.7333 27.6666L37.9333 27.6666C37.6066 27.6666 37.4432 27.6666 37.3184 27.7302C37.2087 27.7862 37.1194 27.8754 37.0635 27.9852C36.9999 28.1099 36.9999 28.2733 36.9999 28.6V31.4C36.9999 31.7267 36.9999 31.89 36.9363 32.0148C36.8804 32.1246 36.7912 32.2138 36.6814 32.2697C36.5566 32.3333 36.3933 32.3333 36.0666 32.3333H33.2666C32.9399 32.3333 32.7765 32.3333 32.6518 32.3969C32.542 32.4528 32.4528 32.5421 32.3968 32.6518C32.3333 32.7766 32.3333 32.9399 32.3333 33.2666V36.0666C32.3333 36.3933 32.3333 36.5567 32.2697 36.6815C32.2137 36.7912 32.1245 36.8805 32.0147 36.9364C31.89 37 31.7266 37 31.3999 37H28.5999C28.2732 37 28.1099 37 27.9851 37.0636C27.8753 37.1195 27.7861 37.2087 27.7302 37.3185C27.6666 37.4433 27.6666 37.6066 27.6666 37.9333V40.7333C27.6666 41.06 27.6666 41.2234 27.7302 41.3481C27.7861 41.4579 27.8753 41.5471 27.9851 41.6031C28.1099 41.6666 28.2732 41.6666 28.5999 41.6666L40.7333 41.6666Z" stroke="#6E5BFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M27.6666 23.9333C27.6666 23.6066 27.6666 23.4433 27.7302 23.3185C27.7861 23.2087 27.8753 23.1195 27.9851 23.0636C28.1099 23 28.2732 23 28.5999 23H31.3999C31.7266 23 31.89 23 32.0147 23.0636C32.1245 23.1195 32.2137 23.2087 32.2697 23.3185C32.3333 23.4433 32.3333 23.6066 32.3333 23.9333V26.7333C32.3333 27.06 32.3333 27.2234 32.2697 27.3481C32.2137 27.4579 32.1245 27.5471 32.0147 27.6031C31.89 27.6666 31.7266 27.6666 31.3999 27.6666H28.5999C28.2732 27.6666 28.1099 27.6666 27.9851 27.6031C27.8753 27.5471 27.7861 27.4579 27.7302 27.3481C27.6666 27.2234 27.6666 27.06 27.6666 26.7333V23.9333Z" stroke="#6E5BFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.4999 30.9333C19.4999 30.6066 19.4999 30.4433 19.5635 30.3185C19.6194 30.2087 19.7087 30.1195 19.8184 30.0636C19.9432 30 20.1066 30 20.4333 30H23.2333C23.5599 30 23.7233 30 23.8481 30.0636C23.9578 30.1195 24.0471 30.2087 24.103 30.3185C24.1666 30.4433 24.1666 30.6066 24.1666 30.9333V33.7333C24.1666 34.06 24.1666 34.2234 24.103 34.3481C24.0471 34.4579 23.9578 34.5471 23.8481 34.6031C23.7233 34.6666 23.5599 34.6666 23.2333 34.6666H20.4333C20.1066 34.6666 19.9432 34.6666 19.8184 34.6031C19.7087 34.5471 19.6194 34.4579 19.5635 34.3481C19.4999 34.2234 19.4999 34.06 19.4999 33.7333V30.9333Z" stroke="#6E5BFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.3333 19.2666C18.3333 18.9399 18.3333 18.7766 18.3968 18.6518C18.4528 18.5421 18.542 18.4528 18.6518 18.3969C18.7765 18.3333 18.9399 18.3333 19.2666 18.3333H22.0666C22.3933 18.3333 22.5566 18.3333 22.6814 18.3969C22.7912 18.4528 22.8804 18.5421 22.9363 18.6518C22.9999 18.7766 22.9999 18.9399 22.9999 19.2666V22.0666C22.9999 22.3933 22.9999 22.5567 22.9363 22.6815C22.8804 22.7912 22.7912 22.8805 22.6814 22.9364C22.5566 23 22.3933 23 22.0666 23H19.2666C18.9399 23 18.7765 23 18.6518 22.9364C18.542 22.8805 18.4528 22.7912 18.3968 22.6815C18.3333 22.5567 18.3333 22.3933 18.3333 22.0666V19.2666Z" stroke="#6E5BFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default Mechanism;