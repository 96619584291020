import React from 'react';
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { useList } from 'modules/sphere/hooks';

import * as Grid from "components/Grid";

import Title from "components/Title";
import Spacer from "components/Spacer";
import Button from "components/Button";
import Container from "components/Container";
import SphereCard from "components/SphereCard";

import cls from './Sphere.module.scss';

const Sphere = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { items } = useList({
    params: {
      perPage: 8
    }
  });

  if (!items.length) {
    return null;
  }

  return (
    <section className={cls.section}>
      <Container>
        <Title text={t('spheres')} />

        <Spacer size={50} />

        <Grid.Row>
          {items.map(item => (
            <Grid.Col span={6} key={item.id}>
              <SphereCard
                name={item.name}
                code={item.code}
                onClick={() => navigate(`/function-register?sphere_id=${item.id}`)}
              />
            </Grid.Col>
          ))}
        </Grid.Row>

        <div className={cls.btn}>
          <Button onClick={() => navigate('/spheres')}>
            {t('action_more')}
          </Button>
        </div>
      </Container>
    </section>
  )
}

export default Sphere;