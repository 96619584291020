import React from 'react';

const FolderCode = () => (
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1666 8.66667L13.8651 6.06374C13.4906 5.3146 13.3033 4.94002 13.0239 4.66636C12.7768 4.42435 12.479 4.2403 12.152 4.12753C11.7823 4 11.3635 4 10.5259 4H6.06659C4.7598 4 4.1064 4 3.60727 4.25432C3.16823 4.47802 2.81127 4.83498 2.58757 5.27402C2.33325 5.77315 2.33325 6.42654 2.33325 7.73333V8.66667M2.33325 8.66667H20.0666C22.0268 8.66667 23.0069 8.66667 23.7556 9.04814C24.4141 9.3837 24.9496 9.91913 25.2851 10.5777C25.6666 11.3264 25.6666 12.3065 25.6666 14.2667V19.4C25.6666 21.3602 25.6666 22.3403 25.2851 23.089C24.9496 23.7475 24.4141 24.283 23.7556 24.6185C23.0069 25 22.0268 25 20.0666 25H7.93325C5.97307 25 4.99298 25 4.24429 24.6185C3.58572 24.283 3.05029 23.7475 2.71473 23.089C2.33325 22.3403 2.33325 21.3602 2.33325 19.4V8.66667ZM16.3333 19.75L19.2499 16.8333L16.3333 13.9167M11.6666 13.9167L8.74992 16.8333L11.6666 19.75"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FolderCode;