import React from "react";
import { Routes, Route, Navigate } from "react-router";

import Layout from 'layout';

import Home from 'pages/Home';
import Sphere from "pages/Sphere";
import Statistic from "pages/Statistic";
import Organization from "pages/Organization";
import FunctionView from "pages/FunctionView";
import FunctionRegister from "pages/FunctionRegister";

const App = () => (
  <Layout>
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/spheres" element={<Sphere />} />

      <Route path="/organizations" element={<Organization />} />

      <Route path="/function-register" element={<FunctionRegister />} />

      <Route path="/function-register/view/:id" element={<FunctionView />} />

      <Route path="/statistics" element={<Statistic />} />

      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  </Layout>
);

export default App;
