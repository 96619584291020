import React from 'react';

import Header from "./Header";
import Footer from "./Footer";

import cls from './Layout.module.scss';

const Layout = ({ children }) => {
  return (
    <div className={cls.wrapper}>
      <Header />

      <div className={cls.content}>
        {children}
      </div>

      <Footer />
    </div>
  )
}

export default Layout;