import React from "react";
import { useTranslation } from "react-i18next";

import PaginationBase from "components/Pagination";

import cls from './Pagination.module.scss';

const Pagination = ({ total, current, currentItems, totalItems, pageRangeDisplayed = 5, onChange }) => {
   const { t } = useTranslation();

   return (
      <div className={cls.pagination}>
         <span className={cls.paginationText}>{t('pagination_pages')}:</span>

         <strong className={cls.paginationCount}>{currentItems} dan {totalItems}</strong>

         <PaginationBase
            total={total}
            current={current || 1}
            pageRangeDisplayed={pageRangeDisplayed}
            onChange={onChange}
         />
      </div>
   )
};

export default Pagination;