import React from 'react';
import { Card as BaseCard } from "antd";

import cls from './Card.module.scss';

const Card = ({ variant, icon, name, count, percentage = 0 }) => (
  <BaseCard className={cls[`card-variant-${variant}`]} bordered={false}>
    <div className={cls.content}>
      <div>{icon}</div>
      <div>
        <span className={cls.name}>{name}</span>
        <strong className={cls.count}>{count} <small>ta</small></strong>
      </div>
    </div>

    {!!percentage ? (
      <div className={cls.line}>
        <div className={cls.percentage} style={{ '--percentage': `${percentage}%` }} />
      </div>
    ) : null}
  </BaseCard>
);

export default Card;