import React from 'react';
import { useTranslation } from "react-i18next";

import * as Icons from 'assets/icons';

import * as Grid from "components/Grid";

import Title from "components/Title";
import Spacer from "components/Spacer";

import Card from "../../components/Card";

const DoneWork = ({ title, statistic }) => {
  const { t } = useTranslation();

  return (
    <>
      <Title text={title} />

      <Spacer size={50} />

      <Grid.Row gutter={24}>
        <Grid.Col className="gutter-row" span={6}>
          <Card
            variant='blue'
            icon={<Icons.AllDocument />}
            name={t('all_documents')}
            count={statistic.total}
          />
        </Grid.Col>

        <Grid.Col className="gutter-row" span={6}>
          <Card
            variant='green'
            icon={<Icons.Confirmed />}
            name={t('accept_documents')}
            count={statistic.accept}
          />
        </Grid.Col>

        <Grid.Col className="gutter-row" span={6}>
          <Card
            variant='yellow'
            icon={<Icons.Process />}
            name={t('process_documents')}
            count={statistic.process}
          />
        </Grid.Col>

        <Grid.Col className="gutter-row" span={6}>
          <Card
            variant='red'
            icon={<Icons.Rejected />}
            name={t('reject_documents')}
            count={statistic.reject}
          />
        </Grid.Col>
      </Grid.Row>
    </>
  )
}

export default DoneWork;