import React from 'react';

const ArrowForward = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_27_8071" maskUnits="userSpaceOnUse" x="0" y="0" width="10" height="10">
      <rect x="0.401367" y="0.521484" width="9" height="9" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_27_8071)">
      <path d="M3.40977 8.77246L2.74414 8.10684L5.82852 5.02246L2.74414 1.93809L3.40977 1.27246L7.15977 5.02246L3.40977 8.77246Z" fill="white"/>
    </g>
  </svg>
);

export default ArrowForward;