import React from 'react';

const DoneWork = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="10" fill="#6E5BFF" fillOpacity="0.1"/>
    <mask id="mask0_361_14187" maskUnits="userSpaceOnUse" x="16" y="16" width="28" height="28">
      <rect x="16" y="16" width="28" height="28" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_361_14187)">
      <path d="M29.9997 41.6667C28.3858 41.6667 26.8691 41.3603 25.4497 40.7474C24.0302 40.1353 22.7955 39.3042 21.7455 38.2542C20.6955 37.2042 19.8645 35.9695 19.2523 34.55C18.6395 33.1306 18.333 31.6139 18.333 30C18.333 28.3862 18.6395 26.8695 19.2523 25.45C19.8645 24.0306 20.6955 22.7959 21.7455 21.7459C22.7955 20.6959 24.0302 19.8644 25.4497 19.2515C26.8691 18.6394 28.3858 18.3334 29.9997 18.3334C31.2636 18.3334 32.4594 18.5181 33.5872 18.8875C34.715 19.257 35.7552 19.7723 36.708 20.4334L35.0163 22.1542C34.2775 21.6875 33.49 21.3228 32.6538 21.0599C31.8177 20.7978 30.933 20.6667 29.9997 20.6667C27.4136 20.6667 25.2117 21.5755 23.394 23.3932C21.5756 25.2117 20.6663 27.4139 20.6663 30C20.6663 32.5862 21.5756 34.7884 23.394 36.6069C25.2117 38.4245 27.4136 39.3334 29.9997 39.3334C32.5858 39.3334 34.7881 38.4245 36.6065 36.6069C38.4242 34.7884 39.333 32.5862 39.333 30C39.333 29.65 39.3136 29.3 39.2747 28.95C39.2358 28.6 39.1775 28.2598 39.0997 27.9292L40.9955 26.0334C41.2094 26.6556 41.3747 27.2973 41.4913 27.9584C41.608 28.6195 41.6663 29.3 41.6663 30C41.6663 31.6139 41.3599 33.1306 40.747 34.55C40.1349 35.9695 39.3038 37.2042 38.2538 38.2542C37.2038 39.3042 35.9691 40.1353 34.5497 40.7474C33.1302 41.3603 31.6136 41.6667 29.9997 41.6667ZM28.3663 35.3667L23.408 30.4084L25.0413 28.775L28.3663 32.1L40.033 20.4042L41.6663 22.0375L28.3663 35.3667Z" fill="#6E5BFF"/>
    </g>
  </svg>
);

export default DoneWork;