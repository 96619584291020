import React from 'react';

import * as Icons from "assets/icons";

import cls from './NameAndLink.module.scss';

const NameAndLink = ({ index, name, link }) => {
  const onCopy = () => {
    navigator.clipboard.writeText(link)
      .catch(err => {
        console.log('Error in link copy: ', err);
      });
  }

  return (
    <div className={cls.wrapper}>
      <div className={cls.index}>{index}</div>
      <div className={cls.name}>{name}</div>
      <div className={cls.link}>
        <div className={cls.text}>{link}</div>
        <div className={cls.icon} onClick={onCopy}>
          <Icons.Copy />
        </div>
      </div>
    </div>
  )
};

export default NameAndLink;