import React from 'react';
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useUrlSearchParams } from "use-url-search-params";
import { Tabs, Breadcrumb } from 'antd';

import { useList as useOrganization } from 'modules/organization/hooks';
import { useAll as useOrganizationCategory } from 'modules/organizationCategory/hooks';

import * as Grid from "components/Grid";

import Card from "components/Card";
import Title from "components/Title";
import Spacer from "components/Spacer";
import Container from "components/Container";

import cls from './Organization.module.scss';

const Organization = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [params, setParams] = useUrlSearchParams();

  const category = useOrganizationCategory();

  const organization = useOrganization({
    params: {
      perPage: 1_000_000_000,
      sort: {
        name: 'name',
        direction: 'asc'
      },
      filter: [
        {
          key: 'organization_category.name',
          operation: '=',
          value: params.category === 'all' ? '' : params.category,
          type: 'STRING'
        },
      ]
    }
  });

  const getOrganizationCount = () => {
    let result = 0;

    category.items.forEach(item => {
      result += item.count;
    });

    return result;
  }

  return (
    <Container>
      <Spacer size={35} />

      <Breadcrumb separator=">">
        <Breadcrumb.Item href='/'>{t('home')}</Breadcrumb.Item>
        <Breadcrumb.Item>{t('organizations')}</Breadcrumb.Item>
      </Breadcrumb>

      <Spacer size={32} />

      <Title align='left' text={t('organizations')} />

      <Spacer size={40} />

      <Tabs
        defaultActiveKey='all'
        items={[
          { key: 'all', label: (
              <div className={cls.tab}>
                <div className={cls.tabName}>{t('all')}</div>
                <div className={cls.tabCount}>{getOrganizationCount()}</div>
              </div>
            )},
          ...category.items.map(item => ({
            key: item.name,
            label: (
              <div className={cls.tab}>
                <div className={cls.tabName}>{item.name}</div>
                <div className={cls.tabCount}>{item.count}</div>
              </div>
            )
          }))
        ]}
        onChange={key => setParams({ category: key })}
      />

      <Spacer size={40} />

      {organization.items.length ? (
        <Grid.Row gutter={[24, 24]}>
          {organization.items
            .sort((a, b) => {
              const nameA = a.name[i18n.language].toUpperCase();
              const nameB = b.name[i18n.language].toUpperCase();
              if(nameA > nameB) return 1;
              if(nameA < nameB) return -1;
              return 0;
            })
            .map(item => (
              <Grid.Col span={6} key={item.id}>
                <Card
                  uuid={item.file.uuid}
                  name={item.name[i18n.language]}
                  count={item.count}
                  onClick={() => navigate(`/function-register?organization_id=${item.id}`)}
                />
              </Grid.Col>
          ))}
        </Grid.Row>
      ) : <div style={{ textAlign: 'center', color: 'red' }}>{t('no_data')}</div>}

      <Spacer size={140} />
    </Container>
  )
}

export default Organization;