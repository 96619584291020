import React from 'react';
import cx from "classnames";

import cls from './Title.module.scss';

// variant = blue | white
// align = center | left

const Title = ({ text, align = 'center', variant = 'blue' }) => (
  <div className={cx(cls.wrapper, cls[`wrapper-align-${align}`], cls[`wrapper-variant-${variant}`])}>
    <div className={cls.title}>{text}</div>
    <div className={cls.line}></div>
  </div>
)

export default Title;