import React from 'react';

import config from 'config';

import cls from './Card.module.scss';

const Card = ({ uuid, name, count, onClick }) => (
  <div onClick={() => onClick && onClick()} className={cls.card}>
    <img className={cls.image} src={`${config.api.img_url}/${uuid}`} alt={name} />

    <p className={cls.name}>{name}</p>

    <p className={cls.count}>
      {count} <small className={cls.suffix}>ta</small>
    </p>
  </div>
);

export default Card;