import React, { useState } from 'react';
import cx from 'classnames';

import InputBase from 'antd/lib/input';

import cls from './Input.module.scss';

const Input = ({
  name,
  value,
  size='large',
  type = 'text',
  state = 'default',
  placeholder,
  disabled,
  message,
  autoFocus,
  onChange,
  onBlur,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={cx(
        cls.wrapper,
        isFocused && cls['wrapper--focused'],
        disabled && cls['wrapper--disabled'],
        state && cls[`wrapper--state-${state}`]
      )}
    >
      <InputBase
        className={cls.input}
        { ...props }
        {...{ name, value, size, type, placeholder, disabled, autoFocus }}
        onChange={e => onChange && onChange(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={e => {
          setIsFocused(false);
          onBlur && onBlur(e);
        }}
        autoComplete='off'
      />

      {!!message && <div className={cls.message}>{message}</div>}
    </div>
  )
}

export default Input;