import React from 'react';

const Minus = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.6431 10.9834H2.35735C2.23949 10.9834 2.14307 11.0798 2.14307 11.1977V12.8048C2.14307 12.9227 2.23949 13.0191 2.35735 13.0191H21.6431C21.7609 13.0191 21.8574 12.9227 21.8574 12.8048V11.1977C21.8574 11.0798 21.7609 10.9834 21.6431 10.9834Z"
        fill="currentColor"
      />
      <path
        d="M21.6431 10.9834H2.35735C2.23949 10.9834 2.14307 11.0798 2.14307 11.1977V12.8048C2.14307 12.9227 2.23949 13.0191 2.35735 13.0191H21.6431C21.7609 13.0191 21.8574 12.9227 21.8574 12.8048V11.1977C21.8574 11.0798 21.7609 10.9834 21.6431 10.9834Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M21.6431 10.9834H2.35735C2.23949 10.9834 2.14307 11.0798 2.14307 11.1977V12.8048C2.14307 12.9227 2.23949 13.0191 2.35735 13.0191H21.6431C21.7609 13.0191 21.8574 12.9227 21.8574 12.8048V11.1977C21.8574 11.0798 21.7609 10.9834 21.6431 10.9834Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
  </svg>
);

export default Minus;
