import React from 'react';
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import * as Icons from 'assets/icons';

import config from "config";

import Container from "components/Container";

import cls from './Header.module.scss';

const Header = () => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <header className={cx(cls.header, location.pathname !== '/' && cls['header-static'])}>
      <Container>
        <div className={cls.content}>
          <div className={cls.left}>
            <Link className={cls.logo} to='/'>
              <Icons.Logo size={40} />

              <span className={cls.name}>
                O’zbekiston Respublikasi <br/> Adliya vazirligi
              </span>
            </Link>

            <span className={cls.line} />

            <span className={cls.siteName}>{t('register_of_state_functions')}</span>

            <span className={cls.test}>{t('Sayt test rejimida ishlayabdi')}</span>
          </div>

          <a className={cls.enter} href={config.app.partner_url} target='_blank'>
            {t('action_enter')}
          </a>
        </div>
      </Container>
    </header>
  )
}

export default Header;