import React from 'react';
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import * as Icons from 'assets/icons';

import { useApplication } from 'modules/statistic/hooks';

import * as Grid from "components/Grid";

import Title from "components/Title";
import Button from "components/Button";
import Spacer from "components/Spacer";
import Container from "components/Container";

import Card from "./components/Card";

import cls from './Statistic.module.scss';

const Statistics = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { item } = useApplication();

  return (
    <section className={cls.section}>
      <Container>
        <Title text={t('statistics')} />

        <Spacer size={50} />

        <Grid.Row gutter={24}>
          <Grid.Col className="gutter-row" span={6}>
            <Card
              variant='blue'
              icon={<Icons.AllDocument />}
              name={t('all_documents')}
              count={item.total}
            />
          </Grid.Col>

          <Grid.Col className="gutter-row" span={6}>
            <Card
              variant='green'
              icon={<Icons.Confirmed />}
              name={t('accept_documents')}
              count={item.accept}
              percentage={29}
            />
          </Grid.Col>

          <Grid.Col className="gutter-row" span={6}>
            <Card
              variant='yellow'
              icon={<Icons.Process />}
              name={t('process_documents')}
              count={item.process}
            />
          </Grid.Col>

          <Grid.Col className="gutter-row" span={6}>
            <Card
              variant='red'
              icon={<Icons.Rejected />}
              name={t('reject_documents')}
              count={item.reject}
            />
          </Grid.Col>
        </Grid.Row>

        <div className={cls.btn}>
          <Button onClick={() => navigate('/statistics')}>
            {t('action_more')}
          </Button>
        </div>
      </Container>
    </section>
  )
}

export default Statistics;