import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Icons from 'assets/icons';

import cls from './SphereCard.module.scss';

const SphereCard = ({ name, code, onClick }) => {
  const { t } = useTranslation();

  return (
    <div onClick={() => onClick && onClick()} className={cls.card}>
      <div className={cls.icon}>
        <Icons.CheckDone />
      </div>
      <div className={cls.info}>
        <p className={cls.name}>{name}</p>
        <p className={cls.code}>{code}</p>
      </div>
      <div className={cls.more}>
        {t('more')} <Icons.ArrowRight />
      </div>
    </div>
  )
};

export default SphereCard;