import React from 'react';

const Youtube = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_27_8116)">
      <path d="M10.3357 14.1072L13.6639 12.1761L10.3357 10.2356V14.1072Z" fill="white"/>
      <path d="M11.9998 0.171387C9.62639 0.171387 7.30631 0.875175 5.33292 2.19375C3.35953 3.51233 1.82146 5.38647 0.913207 7.57919C0.00495523 9.7719 -0.232685 12.1847 0.230338 14.5125C0.693361 16.8402 1.83625 18.9784 3.51448 20.6567C5.19271 22.3349 7.33091 23.4778 9.65868 23.9408C11.9865 24.4038 14.3993 24.1662 16.592 23.2579C18.7847 22.3497 20.6588 20.8116 21.9774 18.8382C23.296 16.8648 23.9998 14.5448 23.9998 12.1714C23.9998 8.98879 22.7355 5.93654 20.4851 3.68611C18.2346 1.43567 15.1824 0.171387 11.9998 0.171387ZM18.3156 14.2951C18.3158 14.6089 18.2541 14.9197 18.1342 15.2098C18.0142 15.4998 17.8383 15.7633 17.6164 15.9853C17.3946 16.2073 17.1311 16.3834 16.8412 16.5036C16.5512 16.6238 16.2405 16.6856 15.9266 16.6856H8.07292C7.75907 16.6856 7.44829 16.6238 7.15835 16.5036C6.8684 16.3834 6.60498 16.2073 6.38312 15.9853C6.16127 15.7633 5.98533 15.4998 5.86537 15.2098C5.74541 14.9197 5.68377 14.6089 5.68397 14.2951V10.0477C5.68377 9.73385 5.74541 9.42303 5.86537 9.13301C5.98533 8.84299 6.16127 8.57944 6.38312 8.35744C6.60498 8.13544 6.8684 7.95933 7.15835 7.83918C7.44829 7.71902 7.75907 7.65718 8.07292 7.65718H15.9266C16.2405 7.65718 16.5512 7.71902 16.8412 7.83918C17.1311 7.95933 17.3946 8.13544 17.6164 8.35744C17.8383 8.57944 18.0142 8.84299 18.1342 9.13301C18.2541 9.42303 18.3158 9.73385 18.3156 10.0477V14.2951Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_27_8116">
        <rect width="24" height="24" fill="white" transform="translate(-0.000244141 0.171387)"/>
      </clipPath>
    </defs>
  </svg>
);

export default Youtube;