import get from 'lodash/get';

import sizeFormat from './sizeFormat';

export const getMeta = (item) => ({
  totalPages: get(item, 'total_pages') || 0,
  totalItems: get(item, 'total_count') || 0,
  current: get(item, 'page') ? get(item, 'page') + 1 : 1,
  perPage: get(item, 'size') || 1
});

export const getFile = (item) => ({
  id: get(item, 'id') || '',
  uuid: get(item, 'uuid') || '',
  name: get(item, 'file_name') || '',
  size: sizeFormat(get(item, 'size') || 0),
  extension: get(item, 'extension') || ''
});

export const getMultiName = (item) => ({
  uz: get(item, 'uz') || '',
  oz: get(item, 'oz') || '',
  ru: get(item, 'ru') || ''
});

export const getIdAndName = (item) => ({
  id: get(item, 'id') || '',
  name: get(item, 'name') || ''
});

export const getIdAndMultiName = (item) => ({
  id: get(item, 'id') || '',
  name: getMultiName(get(item, 'name'))
});

export const getOrganization = (item) => ({
  id: get(item, 'id') || '',
  type: get(item, 'type') || '',
  name: getMultiName(get(item, 'name'))
});

export const getFunction = (item) => ({
  id: get(item, 'id') || '',
  code: get(item, 'code') || 0,
  name: getMultiName(get(item, 'name'))
});

export const getBasisType = (item) => ({
  id: get(item, 'id') || '',
  lexId: get(item, 'lex_id') || '',
  name: getMultiName(get(item, 'name'))
});

export const getTask = (item) => ({
  id: get(item, 'id') || '',
  title: getMultiName(get(item, 'title')),
  file: getFile(get(item, 'file_response'))
});

export const getFunctionUser = (item) => ({
  id: get(item, 'id') || '',
  name: get(item, 'name') || ''
});

export const getReestrProject = (item) => ({
  id: get(item, 'reestr_project_id') || '',
  fullName: get(item, 'full_name') || '',
  shortDescription: get(item, 'short_description') || '',
  target: get(item, 'target') || '',
  tasks: get(item, 'tasks') || '',
});

export const getNameAndLink = (item) => ({
  name: get(item, 'name') || '',
  link: get(item, 'link') || ''
});